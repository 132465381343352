import '../Style/btn.css';
import {Link} from 'react-router-dom';

function Btn(props){
    return(
       
        <div class='component_btn'>
            <Link to={props.pages}>   
                <input type="button" value={props.info} className='component_btn_input' />    
            </Link>
        </div>               
    
    )
}
export default Btn;
