import '../Style/addmoney.css';
import {Link} from 'react-router-dom';
import { useState } from 'react';

function Addmoney(){
    const [swap, setSwap] = useState();

    
    return(
        

        <div class='dashboard_frame'>

            
            <Link to='/dashboard'>
                <div class='arrow_back'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_37_1750)">
                        <path d="M5 12H19" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 12L11 18" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 12L11 6" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_37_1750">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                    </svg>
                </div>
            </Link>

            <div class='am_text'><p>Add money</p></div>
            
            <div class='arrow_down'>
                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                <g clip-path="url(#clip0_37_1762)">
                    <path d="M5 7.5L10 12.5L15 7.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </g>
                <defs>
                    <clipPath id="clip0_37_1762">
                    <rect width="20" height="20" fill="white"/>
                    </clipPath>
                </defs>
                </svg>
            </div>
            <div class='usdt'>
                <p>USDT</p>
            </div>
            <div class='flag'>
            <img src={require ('./assets/flag.png')} alt='text' />
            </div>
            
            {swap === 0 ?(
                        <div class='am_inset'>
                           <form>
                         
                               <div class='inp1'>
                                   <label for='' id='usdt_label'>Amount in USD</label>
                                   <input type='number' placeholder='$0.00' />
                               </div>
                               
                               <div class='inp2'>
                                   <label for='' id='NGN_label'>Amount in NGN</label>
                                   <input type='number' placeholder='N0.00'/>
                               </div>
                           
                                      <div class='swap' onClick={() => setSwap(1)}>
                                           <img src={require ('./assets/Swap.png')} alt='text' />
                                           <p>Swap</p>
                                       </div>
                               <button type='submit'>Proceed</button>
                           </form>
                           
                       </div>
                         ):                             
                         <div class='am_inset'>
                         <form>
                             
                             <div class='inp1'>
                                 <label for='' id='usdt_label2' >Amount in NGN</label>
                                 <input type='number' placeholder='N0.00'/>
                             </div>

                             <div class='inp2'>
                                 <label for='' id='NGN_label'>Amount in USD</label>
                                 <input type='number' placeholder='$0.00' />
                             </div>
                         
                                    <div class='swap' onClick={() => setSwap(0)}>
                                         <img src={require ('./assets/Swap.png')} alt='text' />
                                         <p>Swap</p>
                                     </div>
                            <Link to={'/depositoption'}>
                                <button type='submit'>Proceed</button>
                            </Link>
                             
                         </form>
                         
                          </div> 
                         }
          
            
        </div>
    )
}
export default Addmoney;