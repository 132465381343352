import { Link } from "react-router-dom";

function Button(){
    return(
        <div>
            <div class="db_button">
                <Link to='/sendmoney'>
                    <div class='db_btn1'>
                    
                    <input type='button' value="Send Money" class='send_money' />
                    </div>
                </Link>

                <Link to='/addmoney'>
                    <div class='db_btn2'>
                        <input type='button' value="Add Money" />
                    </div>
                </Link>

            </div>
        </div>

    )
}
export default Button;