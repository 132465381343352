import '../Style/header.css';

const Header = (props) => {
    return(
        <div className='header'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <g clip-path="url(#clip0_32_793)">
                <path d="M5 12H19" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 12L11 18" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M5 12L11 6" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </g>
            <defs>
                <clipPath id="clip0_32_793">
                <rect width="24" height="24" fill="white"/>
                </clipPath>
            </defs>
            </svg>
            <p>{props.tittle}</p>
        </div>
    )
}
export default Header;