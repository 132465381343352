import { Link } from "react-router-dom";
import '../Style/signup.css';

const Signup = () => {
    return(
        
        <div class="container">
            <img src={require('../pages/assets/Group2.jpg')} alt="text" />
            <div className='inset_container'>
                <div class='text'>
                    <h3>Create your free account</h3>
                </div>
                
                <div class='progress_bar'>
                    <div class='pb1'></div>
                    <div class='pb2'></div>
                    <div class='pb3'></div>

                </div>
                <div class='input_box'>
                    <form>
                        <div>
                            <input type='text'  placeholder='Jeremiah Juba' />
                        </div>
                        <div>
                            <input type='email' placeholder='Email adress' />
                        </div>
                        <div>
                            <input type='text' placeholder="Password" />
                        </div>
                        
                        <Link to='/setpin' class='lnk'><button>Sign up</button></Link>
                    </form>

                </div>
                <div>
                    <p>Already have an account? <Link to='/login' class='lnk'>Sign in</Link></p>
                </div>
            </div>
            
        </div>
    )
}
export default Signup;