
function Transactions(){
    return(
        <div class='component3'>
            <p>Asset updates</p>
            
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <g clip-path="url(#clip0_13_189)">
                            <path d="M5 7.5L10 12.5L15 7.5" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_13_189">
                            <rect width="20" height="20" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
            <div class='component3_scroll'>
                    <div class='each_transact'>
                        <img src={require('../pages/assets/Bitcoin.jpg')} alt='text' />
                        <div class='transact_inset'>
                            <div class='asset_name'>
                                <p>Bitcoin</p>
                                <h5>BTC</h5>
                            </div>
                            <div class='asset_price'>
                                <p>$27.54</p>
                                <h5>N30,456,000</h5>
                            </div>
                        </div>
                
                    </div>
                    <div class='each_transact2'>
                        <img src={require('../pages/assets/Bitcoin.jpg')} alt='text' />
                        <div class='transact_inset'>
                            <div class='asset_name'>
                                <p>Bitcoin</p>
                                <h5>BTC</h5>
                            </div>
                            <div class='asset_price'>
                                <p>$27.54</p>
                                <h5>N30,456,000</h5>
                            </div>
                        </div>
                
                    </div> 
                    <div class='each_transact3'>
                        <img src={require('../pages/assets/Bitcoin.jpg')} alt='text' />
                        <div class='transact_inset'>
                            <div class='asset_name'>
                                <p>Bitcoin</p>
                                <h5>BTC</h5>
                            </div>
                            <div class='asset_price'>
                                <p>$27.54</p>
                                <h5>N30,456,000</h5>
                            </div>
                        </div>
                
                    </div> 
                    
            </div>


        
        </div>
    )
}
export default Transactions;