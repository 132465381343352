import '../Style/splash1_style.css';
import {Link} from 'react-router-dom';

function Frame(){
    return(
        <Link to='/splash2'>
            <div class="frame">
                <div class='splash_logo'>
                    <img src={require('../../src/pages/assets/Group.png')} alt='text' />
                </div>
                
                <div class="copyright">
                    <p>copyright 2024</p>
                </div>
            </div>
        </Link>

    )
}
export default Frame;