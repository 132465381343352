import '../Style/splash2_style.css';
import {Link} from 'react-router-dom';

function Introframe(){
    return(
        <div class="frame">
            <div>
            <img src={require('../pages/assets/Vector.png')} alt='text' />
            </div>
            
            <h2>Unlock Global Currency Freedom with Hijuba</h2>
            <p>Proceed to setting up your account.</p>
            <Link to='/signup'>
            <div class="arrow">
                <div class='inset'>
                   <img src={require('../pages/assets/Group1.png')} alt='text' />
                </div>
                    <img src={require('../pages/assets/Frame.png')} alt='text' /> 
                </div>
            </Link>

        </div>
    )
}
export default Introframe;