import '../Style/proof.css';
import {Link} from 'react-router-dom';
 

function Confirmproof(){
    return (
        <div>
            <div class='container'>
                <div class='proof'>
                    <Link to={'../proof'}>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <g clip-path="url(#clip0_37_1842)">
                                <path d="M5 12H19" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5 12L11 18" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                <path d="M5 12L11 6" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                            <defs>
                                <clipPath id="clip0_37_1842">
                                <rect width="24" height="24" fill="white"/>
                                </clipPath>
                            </defs>
                        </svg>
                    </Link>

                    
                    <p>Upload Proof of Payment</p>
                    
                    <h2>$1,200.00</h2>
                    <h3>N1,000,345.00</h3>
                    <div class='photo_container'>
                        <img src={require ('./assets/rectangle.png')} alt='text' />
                    </div>
                    <div class='file_container' >
                        <input type='file' placeholder='+  Tap to Attach Image' />
                    </div>
                </div>

                <div class='proof_inset'>
                    <Link to='../deposuccess'>
                        <input type='button' class='proof_btn_confirm' value='Submit' />                       
                    </Link>
                </div>
            </div>
        </div>
    )
}
export default Confirmproof;