import '../Style/success.css';
import {Link} from 'react-router-dom'

function Deposuccess(){
    return (
        <div>
            <div class='container'>
                <div class='amount_container'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <g clip-path="url(#clip0_37_1873)">
                        <path d="M5 12H19" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 12L11 18" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M5 12L11 6" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_37_1873">
                        <rect width="24" height="24" fill="white"/>
                        </clipPath>
                    </defs>
                    </svg>
                    <p>Upload Proof of Payment</p>
                    <h2>$1,200.00</h2>
                    <h5>N1,000,345.00</h5>
                    <div class='image_frame'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
    <g clip-path="url(#clip0_37_1884)">
        <path d="M31.25 16.6667H31.2708" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M26.0417 43.75H12.5C10.8424 43.75 9.25269 43.0915 8.08058 41.9194C6.90848 40.7473 6.25 39.1576 6.25 37.5V12.5C6.25 10.8424 6.90848 9.25269 8.08058 8.08058C9.25269 6.90848 10.8424 6.25 12.5 6.25H37.5C39.1576 6.25 40.7473 6.90848 41.9194 8.08058C43.0915 9.25269 43.75 10.8424 43.75 12.5V26.0417" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M6.25 33.3333L16.6667 22.9167C18.6 21.0563 20.9833 21.0563 22.9167 22.9167L31.25 31.25" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M29.1667 29.1666L31.2501 27.0833C32.6459 25.7416 34.2709 25.3666 35.7959 25.9583" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M33.3333 39.5833H45.8333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M39.5833 33.3333V45.8333" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
        <clipPath id="clip0_37_1884">
        <rect width="50" height="50" fill="white"/>
        </clipPath>
    </defs>
                        </svg>
                    </div>
                </div>

                <div class='success_inset'>
                    
                    <img src={require ('./assets/Group54.jpg')} alt='text' />
                    <p>Transaction Submitted Successful</p>
                    <Link to='../dashboard'>
                        <div class='success_btn'>
                            <input type='button' class='btn' value='Close'/>
                        </div>
                    
                    </Link>
                    
                </div>
            </div>
        </div>
    )
}
export default Deposuccess;