import '../Style/dashboard.css';
import Component1 from '../components/dbcomponent1';
import Component2 from '../components/dbcomponent2';
import Button from '../components/dbbutton';
import Transactions from '../components/transactions';
import Bottomenu from '../components/bottomenu';
// import {Link} from 'react-router-dom';

function Frame(){
    return(
        
            <div class="dashboard_frame">
                <div class='scroll'>
                    <Component1 />
                    <Component2 />
                    <Button />
                    <Transactions />
                    <Bottomenu />
                    
                </div>
                <div>

                </div>

                
            </div>

    )
}
export default Frame;