import '../Style/bottomenu.css';

function Bottomenu(){
    return(
        <div class='bottom_menu'>
            <div class='wallet_container'>
                <svg xmlns="http://www.w3.org/2000/svg" width="85" height="85" viewBox="0 0 85 85" fill="none">
                <circle cx="42.5" cy="42.5" r="42.5" fill="#CDFF65"/>
                </svg>
                <div class='wallet'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
                    <g clip-path="url(#clip0_13_132)">
                        <path d="M22.6667 10.6667V6.66668C22.6667 6.31305 22.5262 5.97392 22.2762 5.72387C22.0261 5.47382 21.687 5.33334 21.3334 5.33334H8.00004C7.2928 5.33334 6.61452 5.61429 6.11442 6.11439C5.61433 6.61449 5.33337 7.29277 5.33337 8.00001M5.33337 8.00001C5.33337 8.70725 5.61433 9.38553 6.11442 9.88563C6.61452 10.3857 7.2928 10.6667 8.00004 10.6667H24C24.3537 10.6667 24.6928 10.8072 24.9429 11.0572C25.1929 11.3073 25.3334 11.6464 25.3334 12V16M5.33337 8.00001V24C5.33337 24.7073 5.61433 25.3855 6.11442 25.8856C6.61452 26.3857 7.2928 26.6667 8.00004 26.6667H24C24.3537 26.6667 24.6928 26.5262 24.9429 26.2762C25.1929 26.0261 25.3334 25.687 25.3334 25.3333V21.3333" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M26.6666 16V21.3333H21.3333C20.626 21.3333 19.9478 21.0524 19.4477 20.5523C18.9476 20.0522 18.6666 19.3739 18.6666 18.6667C18.6666 17.9594 18.9476 17.2811 19.4477 16.781C19.9478 16.281 20.626 16 21.3333 16H26.6666Z" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_13_132">
                        <rect width="32" height="32" fill="white"/>
                        </clipPath>
                    </defs>
                    </svg>  
                </div>
                <p>Wallet</p>
            </div>
            <div class='menu_box'>
                <div class='home'>
                        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clip-path="url(#clip0_13_136)">
                        <path d="M15.8333 7.25833L11.3891 3.80167C10.9991 3.49826 10.5191 3.33353 10.0249 3.33353C9.53081 3.33353 9.05078 3.49826 8.66078 3.80167L4.21578 7.25833C3.94865 7.46607 3.73252 7.73211 3.58391 8.03612C3.43529 8.34014 3.35812 8.6741 3.35828 9.0125V15.0125C3.35828 15.4545 3.53387 15.8785 3.84643 16.191C4.15899 16.5036 4.58292 16.6792 5.02494 16.6792H15.0249C15.467 16.6792 15.8909 16.5036 16.2035 16.191C16.516 15.8785 16.6916 15.4545 16.6916 15.0125V9.0125C16.6916 8.32667 16.3749 7.67917 15.8333 7.25833Z" stroke="#CDFF65" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M13.3333 12.5C11.4916 13.6108 8.50663 13.6108 6.66663 12.5" stroke="#CDFF65" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_13_136">
                        <rect width="20" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                    </svg>
                    <p>Home</p>
                </div>
                <div class='settings'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clip-path="url(#clip0_13_140)">
                        <path d="M16.5625 5.22501C16.8489 5.3879 17.0867 5.62416 17.2515 5.90948C17.4163 6.1948 17.5021 6.51887 17.5 6.84835V12.9183C17.5 13.5925 17.1308 14.2142 16.535 14.5417L10.91 18.1C10.6311 18.2531 10.3181 18.3334 10 18.3334C9.68186 18.3334 9.36887 18.2531 9.09 18.1L3.465 14.5417C3.17347 14.3824 2.9301 14.1476 2.76034 13.8621C2.59058 13.5765 2.50067 13.2506 2.5 12.9183V6.84751C2.5 6.17335 2.86917 5.55251 3.465 5.22501L9.09 1.90835C9.37711 1.75004 9.69964 1.66702 10.0275 1.66702C10.3554 1.66702 10.6779 1.75004 10.965 1.90835L16.59 5.22501H16.5625Z" stroke="#6C6C6C" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M7.5 10C7.5 10.663 7.76339 11.2989 8.23223 11.7678C8.70107 12.2366 9.33696 12.5 10 12.5C10.663 12.5 11.2989 12.2366 11.7678 11.7678C12.2366 11.2989 12.5 10.663 12.5 10C12.5 9.33696 12.2366 8.70107 11.7678 8.23223C11.2989 7.76339 10.663 7.5 10 7.5C9.33696 7.5 8.70107 7.76339 8.23223 8.23223C7.76339 8.70107 7.5 9.33696 7.5 10Z" stroke="#6C6C6C" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_13_140">
                        <rect width="20" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                    </svg>
                    <p>Settings</p>
                </div>
            </div>
        </div>
    )
}
export default Bottomenu;