import { Link } from "react-router-dom";
import '../Style/login.css';

const Login = () => {
    return(
        <div class="container">
            <img src={require('../pages/assets/Group2.jpg')} alt="text" />
            <div className='inset_container'>
                <div class='text'>
                    <h3>Welcome back! Jeremiah 😊</h3>
                </div>
                
                <div class='progress_bar'>
                    <div class='pbs1'></div>

                </div>
                <div class='input_box'>
                    <form>
                        <div>
                            <input type='email' placeholder='Email adress' />
                        </div>
                        <div>
                            <input type='text' placeholder="Password" />
                        </div>
                        <Link to='/dashboard'>
                            <button>Sign in</button>
                        </Link>
                        
                    </form>

                </div>
                <div>
                    <p>Dont have an account? <Link to='/signup' class='lnk'>Signup</Link></p>
                </div>
            </div>
            
        </div>
    )
}
export default Login;