import Header from '../components/header';
import {Link} from 'react-router-dom';
import Btn from '../components/button';

function Pinconfirmation(){
    const tittle = 'Payment Details';
    const info = 'Confirm';
    const page = '/dashboard';
    return (
        
            <div className="container">
                <div className='pc'>
                    <div className='header'>
                        <Header tittle = {tittle} />
                    </div>
                    <div className='pd_body'>
                        <h2>$1,200.00</h2>
                        <p>1 USD = N890</p>
                        <div className='pc_details'>
                            <div className="to"><h4>To</h4> <p>Jeremiah Juba</p></div>
                            <div className="account_number"><h4>Account Number</h4> <p>2096023884</p></div>
                            <div className="bank_name bn"><h4>Account Name</h4> <p>United Bank of Arfica</p></div>
                        </div>
                    </div>
                </div>
                <div className='pc_cancle'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
                    <g clip-path="url(#clip0_32_1261)">
                        <path d="M20.8333 20.8333L29.1666 29.1667M29.1666 20.8333L20.8333 29.1667" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M25 6.25C40 6.25 43.75 10 43.75 25C43.75 40 40 43.75 25 43.75C10 43.75 6.25 40 6.25 25C6.25 10 10 6.25 25 6.25Z" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_32_1261">
                        <rect width="50" height="50" fill="white"/>
                        </clipPath>
                    </defs>
                    </svg>
                </div>

                <div className="pc_inset">
                    <p>Enter Transaction Pin</p>
                    <div className='pc_input'>
                        <input type="number" className="number" />
                        <input type="number" className="number" />
                        <input type="number" className="number" />
                        <input type="number" className="number" />
                    </div>
                    <div className='pc_btn_pd'>
                        <Link to={page} props = {page}>
                            <Btn pages={page} info={info} />
                        </Link>   
                    </div>
                </div>

            </div>
      
    )
}
export default Pinconfirmation;