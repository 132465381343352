import Introframe from './Splash2';
import Frame from './Splash1';
import Signup from './signup';
import Login from './login';
import Setpin from './setpin';
import Pinc from './pinc';
import Confsignup from './confsignup';
import Dashboard from './dashboard';
import Addmoney from './addmoney';
import Depositoption from './depositoption';
import Proof from './proof';
import Confirmproof from './confirmproof';
import Deposuccess from './deposuccess';
import Sendmoney from './sendmoney';
import Sendoption from './sendoption';
import Paymentdetails from './paymentdetails';
import Pinconfirmation from './pinconfirmation';




// import 'App.css';
import { HashRouter, Routes, Route,} from 'react-router-dom';
import Addaccount from './addaccount';


function App() {
  return (
    <div>
      <HashRouter>
        <Routes>
          <Route index element={<Frame />} />
          <Route path='/Splash1' element={<Frame />} />
          <Route path='/Splash2' element={<Introframe />} />
          <Route path='/Signup' element={<Signup />} />
          <Route path='/Login' element={<Login />} />
          <Route path='/Setpin' element={<Setpin />} />
          <Route path='/Pinc' element={<Pinc />} />
          <Route path='/Confsignup' element={<Confsignup />} />
          <Route path='/Dashboard' element={<Dashboard />} />
          <Route path='/Addmoney' element={<Addmoney />} />
          <Route path='/Depositoption' element={<Depositoption />} />
          <Route path='/Proof' element={<Proof />} />
          <Route path='/Confirmproof' element={<Confirmproof />} />
          <Route path='/Deposuccess' element={<Deposuccess />} />
          <Route path='/Sendmoney' element={<Sendmoney />} />
          <Route path='/Sendoption' element={<Sendoption />} />
          <Route path='/Addaccount' element={<Addaccount />} />
          <Route path='/Paymentdetails' element={<Paymentdetails />} />
          <Route path='/Pinconfirmation' element={<Pinconfirmation />} />
        </Routes>
      </HashRouter>
    </div>

  );
}

export default App;
