import '../Style/depositopt.css';
import {Link} from 'react-router-dom';
function Depositoption(){
        return(
            <div>
                <div class='dashboard_frame'>
                    <div class='depo_option_scroll'>
                        <Link to={'/addmoney'}>
                            <div class='arrow_back'>
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                                <g clip-path="url(#clip0_37_1797)">
                                    <path d="M5 12H19" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5 12L11 18" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M5 12L11 6" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </g>
                                <defs>
                                    <clipPath id="clip0_37_1797">
                                    <rect width="24" height="24" fill="white"/>
                                    </clipPath>
                                </defs>
                                </svg>
                            </div>
                        </Link>

                        <div class='am_text'>
                            <p>Payment Options</p><br/>
                            <h2>How would you like to add money?</h2>
                        </div>

                        <Link to={'https://fluterwave.com'}>
                            <div class='depo_inset'>
                                <div class='depo_tittle'>
                                    <div class='card_logo'>
                                        <p>💳</p>
                                    </div>
                                    <div class='depo_header'>
                                        <div>
                                            <h3>Debit card</h3>
                                        </div>
                                        
                                        <p>Add money from your debit card</p>
                                    </div>
                                    <div class='arrow_foward'> ▶️ </div> 
                                </div>
                                <div class='recomemded'>
                                    <div class='green'>
                                        <p>Recommended</p>
                                    </div>
                                    <div class='red'>
                                        <p>⏩Instant</p>
                                    </div>
                                    <div class='visa'>
                                        <p>💳Visa</p>
                                    </div>

                                </div>
                                <p>⚠️ Only debit cards are allowed, we dont aceept credit cards</p>
                            

                            </div>
                        </Link>

                        <div class='bank-details'>
                            <h3>Bank transfer</h3>
                            <p>Kindly make payment to the details below 
                                and click next to proceed to uploading proof
                                of payment
                            </p>
                            <div class='details1'>
                                <label>Bank Name</label>
                                <p>Gurantee Trust Bank</p>
                            </div>
                            <div class='details2'>
                                <label>Account Number</label>
                                <p>2096023884</p>
                            </div>
                            <div class='details3'>
                                <label>Account Name</label>
                                <p>Hijuba Tech Hub</p>
                            </div>
                        </div>
                    </div>
                    
                    <div class='po_inset'>
                        <h1>$1,200.00</h1>
                        <p>N1,000,345.00</p>
                        <Link to={'../proof'} class='btn'>
                            <button>Proceed</button>
                        </Link>
                        

                    </div>

                </div>     
            </div>
        )
}
export default Depositoption;