
function Component2(){
    return(
        <div>
            <div class='card'> 
                <div class='card_strip'>
                   <svg xmlns="http://www.w3.org/2000/svg" width="311" height="171" viewBox="0 0 311 171" fill="none">
                    <path opacity="0.4" d="M-59 165.975C99.8333 125.642 584.5 82.5 412 -82.525C338.227 -153.101 311.833 -213 250 -216C47 -169 512.3 569.3 787.5 574.5" stroke="url(#paint0_linear_13_177)" stroke-width="8"/>
                    <defs>
                        <linearGradient id="paint0_linear_13_177" x1="383.5" y1="-286.5" x2="11.5" y2="311.5" gradientUnits="userSpaceOnUse">
                        <stop stop-color="#CDFF65"/>
                        <stop offset="0.816675" stop-color="#CDFF65" stop-opacity="0"/>
                        </linearGradient>
                    </defs>
                    </svg>
                </div>
                <p>Wallet Balance</p>
                <div class='down_arrow'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                    <g clip-path="url(#clip0_27_779)">
                        <path d="M5 7.5L10 12.5L15 7.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                        <clipPath id="clip0_27_779">
                        <rect width="20" height="20" fill="white"/>
                        </clipPath>
                    </defs>
                    </svg>
                </div>
                <div class='usd'>
                    <p>USD</p>
                </div>
                
                <img src={require('../pages/assets/flag.png')} alt='text'/>
                <br /><h2>$1,200.45</h2>

            </div>
        </div>
    )
}
export default Component2;