import Header from '../components/header';
import {Link} from 'react-router-dom';
import Btn from '../components/button';

function Paymentdetails(){
    const tittle = 'Payment Details';
    const info = 'Confirm';
    const page = '/pinconfirmation';
    return (
        <div>
            <div className="container">
            <div className='header'>
                <Header tittle = {tittle} />
            </div>
            <div className='pd_body'>
                <h2>$1,200.00</h2>
                <p>1 USD = N890</p>
                <div className='pd_details'>
                    <div className="to"><h4>To</h4> <p>Jeremiah Juba</p></div>
                    <div className="account_number"><h4>Account Number</h4> <p>2096023884</p></div>
                    <div className="bank_name"><h4>Account Name</h4> <p>United Bank of Arfica</p></div>
                </div>
            </div>

            <div className="pd_inset">
                <div className='ac_btn_pd'>
                    <Link to={page} props = {page}>
                        <Btn pages={page} info={info} />
                    </Link>   
                </div>
            </div>

            </div>
        </div>
    )
}
export default Paymentdetails;