
function Component1(){
    return(
        <div class='component1'>
            <img src={require('../pages/assets/Ellipse8.jpg')} alt='text' />
            <p>Welcome back! Jeremiah 🙂</p>
            <div class='notification'>
                <img src={require('../pages/assets/bell.png')} alt='text' />
            </div>
        </div>
    )
}
export default Component1;
