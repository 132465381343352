import '../Style/signupcon.css';
import {Link} from 'react-router-dom';

function Confsignup(){
    return(
        <div class="frame">
            <div class='acs'>
                <p>Account created successfully</p>
            </div>
            
            <div class='p_bar'>
                <div class='pogress1'></div>
                <div class='pogress2'></div>
|
                <div class='pogress3'></div>
                </div>
                <div class='image'>
                    <img src={require('../pages/assets/Group54.jpg')} alt='text'/>
                </div>
                <Link to='/dashboard' class='btn2'>
                    <input type='button' value='Proceed to Dashboard' />
                </Link>
           
        </div>
    )
}
export default Confsignup;