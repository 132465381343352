import  Btn  from '../components/button';
import Header from '../components/header';
import {Link} from 'react-router-dom';



function Addaccount(){
    const info = 'Next';
    const tittle = 'Enter Recipient Details';
    const page = '/paymentdetails';
    return (
        <div>
            <div className="container">
                <div className='header'>
                    <Header tittle={tittle} />
                </div>
                <div className='ac_form'>
                    <form>
                        <label htmlFor="bank_name">Bank Name</label>
                        <select name="" id="">
                            <option value="">Select Bank Name</option>
                            <option value="UBA" >UBA</option>
                            <option value="Polaris">Polaris</option>
                            <option value="Kuda">Kuda</option>
                            <option value="GTBank">GTBank</option>
                        </select>
                        <label htmlFor="">Account Number</label>
                        <input type="number" placeholder='Enter Account Number' />
                        <div className='add'>
                            <input type="button" value='Add' />
                        </div>
                    </form>
                    <div className='ah'>
                        <div className='ah_logo'>JJ</div>
                        <div className='ah_body'>
                            <h4>Alaba Jeremiah Juba</h4>
                            <div className='ah_body_con'>
                                <h5>2001366745</h5>
                                <h6>Access Bank Nigeria</h6>
                            </div>
                        </div>
                        

                    </div>
                </div>

                <div className="add_acct_inset">
                    <h2>$1,200.00</h2>
                    
                    <div className='ac_btn'>
                    <Link to={page} props = {page}>
                        <Btn pages={page} info={info} />
                    </Link>
                        <p>Tap here to change amount currency</p>
                    </div>
                    
                   
                    
                </div>

            </div>
        </div>
    )
}
export default Addaccount;