import '../Style/sendmoney.css';
import {Link} from 'react-router-dom'

function Sendoption(){
    return(
        <div>
            <div class='container'>
                <div class='send_head'>
                    <Link style={{textDecoration: 'none'}} to='../sendmoney' >
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_32_793)">
                            <path d="M5 12H19" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 12L11 18" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 12L11 6" stroke="#121212" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_32_793">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                        </svg>
                    </Link>

                    <div class='send_tittle'>
                        <h3>Who to pay</h3>
                        <p>Select withdrawal method</p>
                    </div>
                </div>

                <div class='send_body'>
                    <Link to='../addaccount' style={{textDecoration: 'none'}}>
                        <div class='method1'>
                            🏚️<h3>Send to bank account</h3>
                            <p>Send money to any bank account in Nigeria</p>
                        </div>
                    </Link>
                   
                    <Link style={{textDecoration: 'none'}} to='/dashboard'>
                        <div class='method2'>
                            👨‍👨‍👧‍👧
                            <h3>Send to contact</h3>
                            <p>Send money to a friend on Hijuba</p>
                        </div>
                    </Link>


                    <div class='method3'>
                        🏚️<h3>P2P</h3>
                        <p>Collect cash from our available agent near you</p>
                    </div>
                </div>

            </div>
        </div>
    )
}
export default Sendoption;