import '../Style/pinc.css';
import {Link} from 'react-router-dom';

function Pinc(){
    return(
        <div class="frame">
            <div class='back_arrow'>
                <Link to='/setpin'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <g clip-path="url(#clip0_13_6)">
                            <path d="M5 12H19" stroke="#EEEFF6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 12L11 18" stroke="#EEEFF6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            <path d="M5 12L11 6" stroke="#EEEFF6" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                        <defs>
                            <clipPath id="clip0_13_6">
                            <rect width="24" height="24" fill="white"/>
                            </clipPath>
                        </defs>
                    </svg>
                </Link>

                
      
            </div>
            <div class='text'>
                <h3>Enter transaction passcode</h3>
            </div>
            <div class='progress_bar'>
                <div class='pb1_sp'></div>
                <div class='pb2_sp'></div>
                <div class='pb3_sp'></div>
            </div>
            <div class='sm_input'>
                <input type='number' maxLength='1' placeholder='X'/>
                <input type='number' placeholder='X'/>
                <input type='number' placeholder='X'/>
                <input type='number' placeholder='X'/>
            
            </div>
            <div class='sp_btn_con'>
                <Link to='/confsignup'>
                    <input type='button' value='Next' />
                </Link>
                
            </div>


        </div>
    )
}
export default Pinc;